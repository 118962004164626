import React from 'react';

import Layout from '../components/Layout';
import Header from '../components/Header';
import Scroll from '../components/Scroll';
import Footer from '../components/Footer';
import Social from '../components/Social';

import demoVideo from '../assets/images/demo.gif';
import demo1 from '../assets/images/demo-1.jpg';
import gplay from '../assets/images/google-play-badge.svg';
import astore from '../assets/images/app-store-badge.svg';
import Testimonials from '../components/Testimonials';

const IndexPage = () => (
  <Layout>
    <Header />

    <header className="masthead" id="top">
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-lg-7 my-auto">
            <div className="header-content mx-auto">
              <Testimonials />
              <Scroll type="id" element="download">
                <a href="#download" className="btn btn-outline btn-xl">
                  Download Now for Free!
                </a>
              </Scroll>
            </div>
          </div>
          <div className="col-lg-5 my-auto">
            <div className="device-container">
              <div className="device-mockup iphone6_plus portrait white">
                <div className="device">
                  <div className="screen">
                    <img src={demoVideo} className="img-fluid" alt="" />
                  </div>
                  <div className="button"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <section className="download bg-primary text-center" id="download">
      <div className="container">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <h2 className="section-heading">
              Discover what all the buzz is about!
            </h2>
            <p>
              Our app is available for iOS (iPhone & iPad) and Android (Phone &
              Tablet). Download now to get started!
            </p>
            <div className="badges">
              <a className="badge-link" href="https://android.erg.zone">
                <img src={gplay} alt="" />
              </a>
              <a className="badge-link" href="https://apple.erg.zone">
                <img src={astore} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="features" id="features">
      <div className="container">
        <div className="section-heading text-center">
          <h2>Amazing Features</h2>
          <p className="text-muted">Check out what you can do with ErgZone!</p>
          <hr />
        </div>
        <div className="row">
          <div className="col-lg-4 my-auto">
            <div className="device-container">
              <div className="device-mockup iphone6_plus portrait white">
                <div className="device">
                  <div className="screen">
                    <img src={demo1} className="img-fluid" alt="" />
                  </div>
                  <div className="button"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 my-auto">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-present text-primary"></i>
                    <h3>Data</h3>
                    <p className="text-muted">
                      See live HR%, Zones, Target Pace/Rate, SPI, DF, Watts,
                      Calories from previous, current and next intervals/splits.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-lock-open text-primary"></i>
                    <h3>Custom Workouts</h3>
                    <p className="text-muted">
                      Unlock new PM5 possibilities: 10s splits, 100k with
                      splits, easy variable workouts with undefined rest.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-present text-primary"></i>
                    <h3>Video Workouts</h3>
                    <p className="text-muted">
                      Row along workouts with partner coaches in the ErgFlix
                      track.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-lock-open text-primary"></i>
                    <h3>Platform for Coaches</h3>
                    <p className="text-muted">
                      Manage workouts, members, and competitions in one single
                      place.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-screen-smartphone text-primary"></i>
                    <h3>Discover Content</h3>
                    <p className="text-muted">
                      Get free workouts from partner coaches and start your
                      membership to unlock weekly private content.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-camera text-primary"></i>
                    <h3>Concept2 Logbook</h3>
                    <p className="text-muted">
                      Workouts are automatically uploaded to C2 Logbook and
                      marked as verified.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="contact bg-primary" id="contact">
      <div className="container">
        <h2>
          We <i className="fas fa-heart"></i> new friends!
        </h2>
        <Social />
      </div>
    </section>

    <Footer />
  </Layout>
);

export default IndexPage;
